.editingList {
  /* padding: 17px 20px !important; */
  justify-content: space-between !important;
}

/* .editlistUl {
 //  height: 262px; 
  overflow: scroll;
  overflow-x: hidden;
}

.editlistUl::-webkit-scrollbar,
.shopAt::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  cursor: pointer;
}

.editlistUl::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.editlistUl::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editlistUl::-webkit-scrollbar-track {
  background: transparent;
}

.editlistUl::-webkit-scrollbar-track:hover {
  background: #ddd;
} */

/* edit seting */
.product-page {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.product-image {
  flex: 0 0 50%;
  padding: 10px;
}

img {
  /* width: 100%; */
  width: auto;
  height: auto;
  object-fit: content;
}

.product-info {
  flex: 0 0 50%;
  padding: 10px;
}

.product-title {
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 20px;
}

.product-price {
  margin-bottom: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #ccc;
}

.sale-price {
  font-weight: bold;
  color: #333;
}

.product-actions {
  display: flex;
  align-items: center;
}

.color-dropdown {
  padding: 5px;
  margin-right: 10px;
}

.add-to-cart {
  padding: 10px 20px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.add-to-cart:hover {
  background-color: #3e8e41;
}

/* editing  */
/* ProductDetails.css */

.container {
  /* width: 300px; */
  /* padding: 25px; */
  /* border: 1px solid #e0e0e0; */
  /* border-radius: 10px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* background-color: #fbfbf8; */
  background-color: #fff;
  height: auto;
  /* padding: 25px; */
}

.container .filterByLastSaved {
  width: 335px;
  position: sticky;
  padding: 10px;
  height: auto;
  background: white;
  border: 1px solid #c6bebe;
  color: gray;
  font-weight: 100;
  display: flex;
}
.container .selectedOptions {
  font-weight: 500;
  color: black;
  padding: 0px 5px;
  width: 283px;
}

.container .title {
  /* -webkit-line-clamp: inherit; */
  /* font-size: 12px; */
  /* font-weight: 300; */
}

.container .addContainer {
  padding: 0px 23px;
}

.container .images-size {
  border-radius: 10px;
  min-width: 113px;
  background: white;
  height: 100%;
  /* margin-top: 12px; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image {
  width: 50px;
  height: auto;
}

/* .title {
  font-size: 16px;
  font-weight: bold;
} */

.subtitle {
  font-size: 14px;
  color: #555;
  font-weight: 200px;
}

/* .price {
  font-size: 20px;
  font-weight: bold;
  color: #d32f2f;
} */

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
}

.on-sale {
  background-color: #e0e0e0;
  color: #555;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 10px;
}

.select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button {
  width: 100%;
  padding: 10px;
  margin: 56px 0px 20px 0px;
  border: none;
  border-radius: 35px;
  background-color: rgba(254, 138, 67, 1);
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.save-button {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  margin-top: 0px;
}

.container h3 {
  font-size: 22px !important;
}

.container .expandDownArrow {
  width: 10px;
  height: 10px;
  border: 1px solid black;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  margin-right: 10px;
  margin-top: -13px;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: absolute;
  left: 93%;
  top: 63%;
}

/* review */

.review {
  /* border-bottom: 1px solid #ddd; */
  /* padding-bottom: 10px; */
  /* margin-bottom: 10px; */
  display: flex;
}

.ratingText {
  margin-left: 84%;
  font-size: 16px;
  position: absolute;
}

.review .text {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.disabled {
  color: gray !important;
  pointer-events: none;
}
