.deleteContainer{
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* left: 19px; */
    padding: 15px;
    position: relative;
    top: -195px;
    /* transform: translate(168%, -270%); */
    width: 335px;
    z-index: 0;
    z-index: 1000;
}

.deleteTextContainer{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}

.deleteBtnsCotainer{
    display: flex;
    gap: 10px;
    width: 288px;
}
.deleteBtnsCotainer button{
    width: 45%;
    border-radius: 20px;
    background: white;
    color: black;
    height: 40px;
    cursor: pointer;
}
.deleteBtnsCotainer button:hover{
    background: black;
    color: white;
}