@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Rubik", sans-serif !important;
  /* font-family: "Roboto"; */
}

:root {
  --primary-color: #3498db;
  --card-gap: 10px;
  --section-gap: 15px;
  --coupons-section-gap: 25px;
  --heading-padding: 0px 14px 14px;
}

#shopAt .slider-content {
  padding: 0px 10px;
}

.display_deals {
  padding: 20px 0px;
}
.shopping_wrapper,
.savedSection {
  gap: var(--section-gap);
}
.couponsSection {
  gap: var(--coupons-section-gap);
}
.favStoreCoupons,
#shopAt .slider-content,
.storeSection,
.favStores,
.addContainer {
  gap: var(--card-gap);
}

.wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* background-color: #f3f4f6; */
  background-color: #f7f8f9;
  /* height: 625px; */
}

.wrapper h3,
.header h3,
.filterHeader {
  font-size: 16px;
  padding: 0px 14px 0px 14px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header h3 {
  padding: 0px;
}

.header_btns {
  padding: 10px 5px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

.display_deals {
  /* padding: 10px 20px; */
  /* background-color: #f3f4f6; */
  background-color: #f7f8f9;
  border-radius: 6px;
  display: block;
  overflow-y: scroll;
  /* height: 625px; */
  /* padding: 20px 0px; */
  cursor: default;
}
.display_couponsApplying {
  overflow: hidden;
}

/* .active{
  border-bottom: 3px solid blue !important;
  font-weight: bold;
} */

.shopping_btn {
  padding: 6px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: transparent;
  color: #000;
  font-size: 18px;
  transition: all 0.2s;
}

.coupons_btn,
.close-icon {
  padding: 6px;
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 18px;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
}

.shopping_btn:hover,
.coupons_btn:hover {
  cursor: pointer;
}

@media screen and (width <= 650px) {
  .shopping_btn {
    font-size: 14px;
  }

  .coupons_btn {
    font-size: 14px;
  }
}

/* scrollbar  */
.checkAddToList::-webkit-scrollbar,
.filterSections::-webkit-scrollbar,
.filterByOptionBox::-webkit-scrollbar,
.favListBox::-webkit-scrollbar,
.display_deals::-webkit-scrollbar,
.FavListSection::-webkit-scrollbar,
.noticationPopup::-webkit-scrollbar {
  width: 4px;
}
.favStoreList::-webkit-scrollbar,
.shopAt::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  cursor: pointer;
}

.noticationPopup::-webkit-scrollbar {
  height: 2px;
}
.checkAddToList::-webkit-scrollbar-thumb,
.filterByOptionBox::-webkit-scrollbar-thumb,
.display_deals::-webkit-scrollbar-thumb,
.favStoreList::-webkit-scrollbar-thumb,
.shopAt::-webkit-scrollbar-thumb,
.FavListSection::-webkit-scrollbar-thumb,
.noticationPopup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.filterByOptionBox::-webkit-scrollbar-thumb:hover,
.display_deals::-webkit-scrollbar-thumb:hover,
.favStoreList::-webkit-scrollbar-thumb:hover,
.shopAt::-webkit-scrollbar-thumb:hover,
.FavListSection::-webkit-scrollbar-thumb:hover,
.noticationPopup::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.display_deals::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.display_deals::-webkit-scrollbar-track:hover {
  background: #ddd;
}

.favListBox::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 6px;
}

.filterSections::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 6px;
}

/* login page css */
.login-page {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
}

.login-button {
  text-decoration: none;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(254, 138, 67, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Tab bar styles */
.tab-bar,
.shipping_info,
.shopAt {
  background-color: rgb(254, 138, 67, 1);
  /* padding: 0px 10px; */
  position: fixed;
  bottom: 0;
  width: 100%;
  /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); */
  display: inline-flex;
  justify-content: space-between;
  list-style: none;
  font-size: 15px;
  line-height: 0.5;
  z-index: 100;
}
.tab-bar .icon {
  display: inline-block;
  margin-right: 20px;
}
.tab-bar li,
.shipping_info li,
.shopAt li {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  /* margin-right: 0px; */
  margin-top: 0px;
  color: #1b1c1d;
  width: 125px;
  border-right: 1px solid #d3cece;
  /* padding-left: 10px; */
  margin-right: 10px;
}
.tab-bar li,
.shopAt li {
  border: none;
  cursor: pointer;
}
.tab-bar {
  gap: 21px;
}
#shipping_section,
#about_store {
  height: auto;
  /* height: 120px; */
}
.shipping_info,
.shopAt {
  background-color: transparent;
  position: sticky;
  bottom: auto;
  justify-content: flex-start;
}
.shopAt {
  overflow-y: hidden;
}
.shipping_info li span {
  margin-top: 10px;
}
.shipping_info li:last-child {
  border: none;
}

.storeList img {
  width: 100px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}
#shopAt {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  gap: 20px;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
/* add component */
.addContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* gap: 32px; */
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding: 0px 30px;
}

.addInfo {
  list-style: none;
  text-align: -webkit-center;
  padding-top: 32px;
}
.addInfo li:nth-child(2) {
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: center;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0px 45px 0px 45px;
}
.addInfo li:nth-child(3) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}
#saveProduct,
.buyProduct,
.createNewList,
.addInfo li:nth-child(4),
.applyFilterBtn,
.clearFilter,
.saveFavSelection {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* max-width: 100%; */
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  /* min-width: 200px; */
  width: 100%;
  height: 40px;
  pointer-events: auto;
  /* color: #fbfbf8 !important; */
  color: #fff;
  background: #fd7e14;
  border-color: #1b1c1d;
  border: none !important;
  border-radius: 100px;
  padding: 0 20px;
  margin: 10px 0px;
}
/* #saveProduct {
  min-width: 87%;
} */

.addInfo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

/* .recentlyViewed { */
/* display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px; */

/* gap: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
} */

/* .recentlyViewed small {
  text-transform: lowercase;
  color: #6c757d;
} */

/* .recentlyViewed li {
  background-color: #f4f2eb;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 120px;
  padding: 10px;
  position: relative;
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background-color: #F4F2EB;
  cursor: pointer;
  position: relative;
} */

/* .recentlyViewed li {
  width: 100%;
  min-width: 335px;
  max-width: 335px;
  max-height: 106px;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 15px;
  border: 1px solid #ffffff;
  display: flex;
  flex: 1 1 0%;
  cursor: pointer;
  height: 130px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 15px;
  position: relative;
} */

/* .recentlyViewed li h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-family: "Roboto";
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .recentlyViewed img {
  min-width: 30%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
} */

.productDetail {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 0 0 34px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: 100%;
  margin-left: 10px;
  background-color: #f4f2eb;
  padding: 12px;
}
.productDetail div:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.productDetail div:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.productDetail div:nth-child(3) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-transform: uppercase;
  -webkit-letter-spacing: 0.05em;
  -moz-letter-spacing: 0.05em;
  -ms-letter-spacing: 0.05em;
  letter-spacing: 0.05em;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: #808178;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  /* margin-top: 38px; */
}
/* #saveProduct, */
/* .applyFilterBtn, */
/* .createNewList {
  transform: translate3d(0px, 0px, 0px);
  pointer-events: auto;
  cursor: pointer;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  max-width: 100%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  min-width: 100%;
  height: 40px;
  pointer-events: auto;
  color: #fbfbf8 !important;
  background: rgb(254, 138, 67, 1);
  border-color: #1b1c1d;
  border: none !important;
  border-radius: 100px;
  padding: 0 20px;
} */

.itemDeals {
  display: flex;
  list-style: none;
  width: inherit;
  /* text-align: center; */
  gap: 10px;
  margin: 0;
  padding: 0;
}
.dealHotness {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.itemDeals li {
  flex: 1;
  cursor: pointer;
}

.listContainer {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  gap: 10px;
  margin-bottom: 5px;
}
.listBtns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  flex-wrap: wrap;
}
.listBtns li {
  font-weight: 500;
  all: unset;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 8px;
  /* padding: 7px; */
  min-width: 20px;
  max-width: 100%;
  cursor: pointer;
  background: white;
  border: 1px solid white;
  color: white;
  overflow-wrap: anywhere;
}
/* .listBtns li:hover{
  background-color:c;
  color: white;
} */
.listContainer h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0;
}
.listContainer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  /* margin-bottom: 8px; */
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.itemDeals li {
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  /* text-align: center; */
  color: #1b1c1d;
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* max-width: calc(100% - 10px); */
}

.searchInput {
  cursor: text !important;
  all: unset;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 12px 10px 12px 24px;
  color: #1b1c1d;
  border: 1px solid gray;
  border-radius: 31px;
}
.searchBox {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* background: #f3f4f6; */
  background: #f7f8f9;
  /* margin: 0 -10px; */
  top: -20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 130px;
  display: flex;
  width: -webkit-fill-available;
  /* top: 0px; */
  z-index: 1000;
}
.filterbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  width: 100%;
  gap: 55px;
}
.searchBox {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* background: #f3f4f6; */
  background: #f7f8f9;
  /* margin: 0 -10px; */
  top: -20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 130px;
  display: flex;
  width: -webkit-fill-available;
  /* top: 0px; */
  z-index: 1000;
}

.filterByLastSaved {
  min-height: 19px;
  /* padding: 9px 12px 10px 16px; */
  cursor: pointer;
  /* background: #fbfbf8; */
  background: #fff;
  /* border: 1px solid #B8B5A5; */
  /* border-radius: 8px; */
  background: #ddd8c2;
  /* border: 1px solid #DDD8C2; */
  color: #1b1c1d;
  /* -webkit-text-fill-color: #1B1C1D; */
  opacity: 1;
  /* -webkit-transition: color 0.3s ease-in-out; */
  transition: color 0.3s ease-in-out;
  /* background: transparent; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  display: block;
  min-width: 0;
  width: 100%;
  border: 0;
  box-sizing: content-box;
  padding: 15px 16px 15px 16px;
  border-radius: 8px;
}
.filterByOptions {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  width: 100%;
  /* background: #fbfbf8; */
  background: #fff;
  border: 1px solid;
  border-color: #b8b5a5;
  border-radius: 10px;
  padding: 10px 16px;
  /* height: 40px; */
  cursor: pointer;
}
.filterOverlay,
.FavListSectionOverlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  z-index: 1000;
  /* height: 765px; */
}

.filterHeaderBox {
  padding: 20px 14px 20px 17px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* background-color: #fbfbf8; */
  background-color: #fff;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: center;
  justify-content: space-around;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
.filterHeader {
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  position: absolute;
}

.arrow {
  float: right;
  /* position: fixed; */
  top: -20%;
  /* transform: translateY(-50%); */
  color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  border: none;
  /* padding: 10px; */
  /* cursor: pointer; */
  z-index: 1;
  display: initial;
  background: #f3f4f6;
  height: 48px;
  width: 42px;
}

.left-arrow,
.right-arrow {
  /* left: 10px; */
  border: none;
  padding: 0px 5px 0px 5px;
  font-size: 20px;
  cursor: pointer;
  background: none;
}

.arrow-btn {
  position: relative;
  padding-right: 30px; /* Adjust as needed */
  font-size: 16px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}

.arrow-btn::after {
  content: "▼"; /* Unicode for a down arrow */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.arrow-btn.rotate::after {
  transform: translateY(-50%) rotate(180deg);
}

/* list and filter  */
.priceevolution,
.filterByOptionBox,
.FavListSection,
.addListForm,
.itemSavingPoints,
.noticationPopup {
  animation: moveUp 0.15s ease-out 1 forwards;
  /* background-color: #fbfbf8; */
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  /* bottom: 58px; */
  cursor: default;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 0;
  max-height: 652px;
  overflow-y: auto;
  padding: 30px;
  position: absolute;
  width: 100%;
  will-change: transform, opacity;
  z-index: 1000;
  overflow-x: hidden;
  gap: 20px;
}

/*.itemSavingPoints {
  height: 328px;
  padding: 20px 36px 20px;
} */

.itemSavingPoints ul {
  list-style: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.checkboxList {
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: flex;
  /* -webkit-flex-direction: column; */
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  justify-content: center;
  /* -webkit-align-items: flex-start; */
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
.checkboxList li input {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: flex;
  /* -webkit-align-items: center; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  justify-content: center;
  /* background-color: #fbfbf8; */
  background-color: #fff;
  border: 1px solid;
  border-color: #c295e9;
  border-radius: 50%;
  /* -webkit-transition: 200ms background-color ease-in-out; */
  transition: 200ms background-color ease-in-out;
}
.checkboxList li {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkboxList li label,
.filterDays {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: #1b1c1d;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding-left: 8px;
}
.filterDays {
  padding: 0;
  font-weight: 500;
}
.filterByOptionBox h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}

/* .filterSections {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
} */

.filterSections {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.filterSections h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.filterbysection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-bottom: 20px; */
}

.listFilter,
.filterByDays {
  /* background: #fbfbf8; */
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  height: unset;
  overflow: hidden;
  transform-origin: left top;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  gap: 0;
  padding: 8px 0px;
  width: 100%;
  margin: 0;
  max-height: 300px;
  border-radius: 10px;
  overflow: auto;
}

.filterByDays li,
.listFilter li {
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: flex;
  /* -webkit-flex-direction: row; */
  -ms-flex-direction: row;
  flex-direction: row;
  /* -webkit-align-items: center; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  outline: none;
  color: #1b1c1d;
  /* -webkit-transition: background 0.2s ease-in-out; */
  transition: background 0.2s ease-in-out;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  padding: 8px 16px;
}
.filterByDays li:hover,
.listFilter li:hover {
  background: #ece9dd;
}

.applyClearFilter {
  align-items: center;
  /* background-color: #fbfbf8; */
  background-color: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin: auto;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  position: inherit;
  width: 94%;
  z-index: 2;
  left: 10px;
}

/* .clearFilter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  max-width: 100%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  min-width: auto;
  height: 24px;
  pointer-events: auto;
  color: #1b1c1d !important;
  background: transparent !important;
  -webkit-text-decoration: underline;
  text-decoration: underline;
} */

/* couponns */
.couponsSection {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
  position: relative;
  height: 560px;
  padding: 0px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
  position: relative;
  height: 560px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: auto;
}
.storeSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  /* padding: 0px 10px 10px; */
}
.favstoreContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.favStores,
.storeList {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  /* padding: 10px; */
}
/* Add this to your CSS file or in a <style> tag */
#shopAt .storeList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 70px;
  /* margin: 10px; */
  /* padding: 10px; */
  background-color: white;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

#shopAt .storeList img.icon {
  max-width: 100%; /* Ensure the image doesn't exceed the list item size */
  max-height: 100%; /* Ensure the image doesn't exceed the list item size */
  padding: 10px; /* Add padding around the image */
}

.favStoreList {
  list-style: none;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: 0;
  gap: 10px;
  align-items: center;
  box-sizing: initial;
  display: flex;
  gap: 10px;
  height: 100%;
  list-style: none;
  /* margin-bottom: 24px; */
  position: relative;
  width: 100%;
  z-index: 1;
  overflow-y: hidden;
}

.favStoreList li {
  display: inline-flex;
  gap: 10px;
  width: auto;
  margin-bottom: 5px;
}
.favStore {
  width: 100%;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: revert-layer;
  align-items: center;
  padding: 0px 10px 0px 0px;
  /* width: 50%; */
  height: 32px;
  border-radius: 100px;
  gap: 8px;
  background-color: #f4f2eb;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1b1c1d;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  border: 1px solid #ebe7e7;
}

.activeStore {
  background-color: rgb(254, 138, 67, 1);
  color: white;
}
.toggleDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #ece9dd;
  background: #fff;
  border-radius: 100px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
}
.favStoreCoupons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  /* gap: 20px; */
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  /* padding-top: 10px; */
}
.favStoreCoupons li {
  padding: 10px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  width: 100%;
}
.favStoreCoupons li .couponInfo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.couponBtns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-top: 8px;
  width: 100%;
}
.couponBtns button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  max-width: 100%;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600;
  font-size: 12px;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  min-width: auto;
  height: 16px;
  pointer-events: auto;
  color: #1b1c1d !important;
  background: transparent !important;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.storeName {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.couponInfo .text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 7px;
  color: #808178;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: justify;
}
.couponTag {
  padding: 1px 6px;
  background: #fd7e14;
  border-radius: 5px 0px 0px 5px;
  min-width: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
.couponTag .text {
  text-transform: uppercase;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: #1b1c1d;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1);
  /* color: #fbfbf8; */
  color: #fff;
}

/* slider section  */
.popularCoupons,
.CouponsAndDeals,
.activeCoupons {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: flex-start;
  /* margin: 15px 0 0; */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  width: 745px;
  height: fit-content;
  border-radius: 5px;
  flex-grow: 1;
  overflow: hidden;
  /* padding: 9px; */
  position: relative;
  width: 100%;
  gap: var(--card-gap);
}
.slider-container {
  gap: 5px;
}

.couponsSection h3 {
  padding: 0 !important ;
}

.noActiveDeals {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  /* margin-top: 10px; */
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
}
.noActiveDeals .imgBox {
  vertical-align: middle;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border: 3px solid black;
  border-radius: 22px;
}

.noActiveDeals h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.noActiveDeals span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: #5b5b5b;
  -webkit-text-decoration: none;
  text-decoration: none;
}

/* Fav sect */
.FavListSection .storeList li,
.FavListSection .favStores li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
/* expand section  */
.expandableDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 28px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 0;
  width: 100%;
  /* margin: 10px 0px 0px 0px; */
}

.expandCoupons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 158px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  transition: transform 0.3s ease;
  width: 100%;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  margin: 0;
  transition: transform 0.3s ease;
}

.expandDownArrow {
  width: 13px;
  height: 13px;
  border: 2px solid black;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  margin-right: 10px;
  margin-top: -13px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.expandUpArrow {
  cursor: pointer;
  margin-top: 5px;
  transform: rotate(137deg);
  transition: transform 0.3s ease;
}

/* add comonent styling  */
@keyframes moveUp {
  from {
    transform: translateY(500px);
  }
  to {
    transform: translateY(0px);
  }
}

/* saved Products */
.savedProducts {
  display: grid;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  padding-top: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.savedSection {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.deleteBtns,
.savedProducts li {
  background-color: #f4f2eb;
  border-radius: 10px;
  color: #1b1c1d;
  display: inline-block;
  flex: 0 0 auto;
  margin-bottom: 10px;
  /* margin-right: 20px; */
  overflow: hidden;
  width: 190px;
  box-shadow: 0px 4px 10px rgb(224 215 215);
}

.savedProducts li img {
  width: 100%;
  height: 257px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  min-height: 280px;
}
.savedProducts li .productDetail {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-start;
  background-color: #f4f2eb;
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  height: auto;
  /* justify-content: space-between; */
  margin: 0 0 0 10px;
  width: 100%;
  /* align-items: center; */
  box-sizing: border-box;
  display: flex;
  /* flex-direction: column; */
  /* gap: 0; */
  /* justify-content: space-between; */
  margin: 0;
  min-height: 116px;
  padding: 8px 12px 12px;
  width: 100%;
  justify-content: flex-start;
}

.productDetail .title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.header {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: 50px;
  min-height: 50px;
  /* line-height: 68px; */
  /* background: #FBFBF8; */
  color: #fff;
  padding: 0 12px;
  /* border-bottom: 1px solid #ECE9DD; */
  width: 100%;
  background-color: #9d4edd;
}
.headerItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  list-style: none;
}
.headerItem li {
  border: none;
}
.headerItem li:first-child {
  align-items: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  display: flex;
  gap: 4px;
  height: 26px;
  justify-content: inherit;
  margin-top: -3px;
  min-width: 66px;
  padding: 4px 6px;
  --tw-bg-opacity: 1;
  background-color: #fd7e14;
  justify-content: space-between;
}
.headerItem li:nth-child(3) {
  --tw-bg-opacity: 1;
  color: #fd7e14;
}

.footer {
  color: white;
  justify-content: space-around;
  width: 100%;
  justify-content: flex-start;
  width: 101%;
  gap: 20px;
  /* z-index: 1000; */
  background-color: #9d4edd;
  padding: 10px;
}
.footer li {
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  gap: 4px;
  margin: 0;
  padding: 0;
  /* width: 25%; */
  width: 74px;
}
.footer li a {
  text-decoration: none;
  color: white;
}
.footer span {
  margin-left: 10px;
  width: 100px;
}

.addListForm {
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* padding: 30px; */
  height: auto;
}
.addListForm input,
textarea {
  color: #1b1c1d;
  -webkit-text-fill-color: #1b1c1d;
  opacity: 1;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  display: block;
  min-width: 0;
  /* width: 100%; */
  border: 0;
  box-sizing: content-box;
  padding: 15px 16px 15px 16px;
  border-radius: 8px;
  border: 2px solid #cbcbcb;
}
.addListForm form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 18px;
  /* padding: 0px 0px 20px 0px; */
}

.threeDots {
  position: absolute;
  float: right;
  right: 0;
  left: 90%;
  top: 79%;
  transform: rotate(90deg);
  font-size: 15px;
  width: 3%;
  height: 14%;
}

/* notification */
.notificationList {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  list-style-type: none;
}

.notificationList li {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  width: 100%;
  padding: 16px 0px;
  /* background-color: #fbfbf8; */
  background-color: #fff;
  border-bottom: 1px solid #ddd8c2;
  color: currentColor;
  overflow-wrap: anywhere;
}

.NotifactionBar {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 4px;
  background: white;
  border-radius: 100px;
}

.NotifactionBar div {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 17px;
  width: 50%;
  height: 32px;
  border-radius: 100px;
  gap: 8px;
  --tw-bg-opacity: 1;
  color: rgb(254 138 67 / var(--tw-bg-opacity));
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: black;
}

.notficationSettingSection {
  padding: 30px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 30px;
  height: inherit;
}
.notficationSettingSection ul {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
.notficationSettingSection ul li {
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
.notficationSettingSection ul li .NotifactionBar {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  min-width: 65px;
  height: 35px;
  width: 0px;
  background-color: #9d4edd;
  transition: color 0.3s ease-in-out;
}
.notficationSettingSection ul li .NotifactionBar div {
  background-color: white;
}

.itemSavedSection {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 107px; */
  flex-direction: column;
  font-size: 54px;
}

/* wishlist card */
.fullfilledDiv {
  padding: 2px 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #1b1c1d;
  background-color: #e4d6f4;
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}
.fullfilledSpan {
  text-transform: uppercase;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.updateListMessage {
  position: absolute;
  top: 151px;
  font-size: 12px;
  padding: 2px 10px;
  color: red;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex-shrink: 0; /* Ensures header does not shrink */
}

.wrapper {
  flex: 1;
  overflow-y: auto;
}

.display_deals {
  /* padding: 10px; */
  box-sizing: border-box;
}

.tab-bar.footer {
  display: flex;
  justify-content: space-around;
  position: sticky;
  bottom: 0;
  width: 100%;
  /* background-color: #fff; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Adjust padding-bottom of wrapper to accommodate footer height */
.display_deals {
  padding-bottom: 20px; /* Footer height */
}

.model-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.model-header h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.model-header-icon {
  font-size: 20px !important; /* Force icon size */
  font-weight: 500;
  cursor: pointer;
}

.stickyButtonContainer {
  position: fixed;
  bottom: 52px;
  left: 0;
  right: 0;
  padding: 20px 30px;
  background: inherit;
  /* z-index: 1000; */
}

.singleProductContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* gap: 32px; */
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  /* padding: 0px 30px; */
}

.editSetingsection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-bottom: 20px; */
}

/**=====================
49. Badge CSS start
==========================**/
/**=====================
49. Badge CSS start
==========================**/
.badge {
  font-family: "Rubik", sans-serif !important;
  font-size: 0.95em;
  font-weight: 500;
  padding: 0.44em 0.7em;
  border-radius: 0.375rem;
}

.badge + .badge {
  margin-left: 5px;
}

.badge svg {
  width: 1em; /* Example SVG size */
  height: 1em; /* Example SVG size */
  padding-top: 3px;
}

/* Round Badge Styles */
.round-badge-primary {
  background-color: rgba(115, 102, 255, 0.1);
  color: #7366ff;
}

.round-badge-danger {
  background-color: #fff7f7;
  color: #fc4438;
}

.round-badge-black {
  background-color: #fff;
  color: black;
}

.round-badge-success {
  background-color: #d9efd6;
  color: #54ba4a;
}

.round-badge-info {
  background-color: #d3f4fe;
  color: #16c7f9;
}

.round-badge-light {
  background-color: rgba(255, 51, 100, 0.08);
  color: #ff3364;
}

.round-badge-light {
  background-color: #e9e9ee;
  color: #52526c;
}

.round-badge-dark {
  background-color: #d3f4fe;
  color: #16c7f9;
}

.round-badge-warning {
  background-color: #ffecc7;
  color: #ffaa05;
}

.notificationCard,
.singleProductCard {
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px; */

  gap: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
}

.notificationCard small,
.singleProductCard small {
  text-transform: lowercase;
  color: #6c757d;
}

.notificationCard li,
.singleProductCard li {
  background-color: #f4f2eb;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 120px;
  /* padding: 10px; */
  position: relative;
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  /* background-color: #F4F2EB; */
  cursor: pointer;
  position: relative;
}

.notificationCard li,
.singleProductCard li {
  /* width: 100%; */
  /* min-width: 335px; */
  /* max-width: 335px; */
  /* max-height: 106px; */
  /* border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 15px;
  border: 1px solid #ffffff; */
  display: flex;
  flex: 1 1 0%;
  cursor: pointer;
  height: 130px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.notificationCard li h3,
.singleProductCard li h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-family: "Rubik", sans-serif !important;
  font-style: normal;
  text-align: inherit;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificationCard img,
.singleProductCard img {
  min-width: 30%;
  height: 130px;
  object-fit: contain;
  border-radius: 10px;
}

.notificationCard title .singleProductCard title {
}

/**=====================
49. Badge CSS Ends
==========================**/
