.modal-overlay {
  position: fixed;
  top: 261px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  /* background: #fbfbf8; */
  background: #fff;
  padding: 30px;
  border-radius: 20px 20px 0 0;
  text-align: center;
  position: relative;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

h2 {
  margin-bottom: 10px;
}

.share-buttons {
  display: flex;
  flex-direction: column;
}

.share-button {
  background: rgba(254, 138, 67, 1);
  color: white;
  border: none;
  border-radius: 35px;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  gap: 10px;
}

.share-button i {
  margin-right: 8px;
}
.noticationPopup .title {
  -webkit-line-clamp: 2;
  font-size: 12px;
  font-weight: 300;
}
