@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Rubik", sans-serif !important;
}

/* section{
    margin-bottom: 44px;
} */
.shopping_wrapper {
  /* border-bottom: 1px solid #e1d8d8; */
  /* gap: 5px; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cards-wrapper {
  padding: 11px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.images_size {
  width: fit-content;
  transition: all 1s;
  height: inherit;
  padding: 4px 0px 10px 0px;
}

.card {
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  justify-content: unset;
  width: auto;
  border-radius: 6px;
  box-shadow: 0 9px 20px rgba(46, 35, 94, 0.07);
  padding: 20px;
  text-wrap: wrap;
  background-color: #ffffff;
}

.card:hover {
  cursor: pointer;
}

.card:hover > .images_size {
  scale: 1.05;
}

.deals_section {
  /* padding: 10px 0px; */
}

.another_card {
  display: grid;
  gap: 10px;
  border-radius: 6px;
  box-shadow: 0 9px 20px rgba(46, 35, 94, 0.07);
  padding: 20px 10px;
  text-wrap: wrap;
  background-color: #ffffff;
  width: auto;
  height: 338px;
  position: relative;
  z-index: 1;
  grid-template-rows: repeat(4, 1fr);
  overflow: hidden;
}

.another_card:hover {
  cursor: pointer;
}

.another_card:hover > .images-size {
  scale: 1.05;
}

.line {
  text-decoration: line-through;
  /* padding-right: 10px; */
  font-weight: 100;
}
.line {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  white-space: nowrap;
}
.price {
  --tw-text-opacity: 1;
  color: rgb(254 138 67 / var(--tw-text-opacity));
}

.images-size {
  object-fit: contain;
  object-position: center;
  overflow: hidden;
  transition: all 0.9s;
  /* width: 92px; */
  height: inherit;
  border-radius: 10px;
  width: 100%;
}

.another-cards-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0px 10px;
  /* padding-bottom: 20px; */
}

.price {
  /* color: brown; */
  font-weight: bold;
  gap: 10px;
  display: flex;
  white-space: nowrap;
}

.title {
  font-weight: 600px;
  /* white-space: pre-wrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.discount,
.saveDeal {
  padding: 10px 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 600;
}
.saveDeal {
  background: none;
  color: black;
  left: 151px;
  position: relative;
  /* top: -360px; */
  z-index: 1000;
  float: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  top: -6px;
  left: calc(100% - 44px);
  position: absolute;
}
.items_wrapper {
  padding: 0px 5px;
}

.loading_section {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1px;
  width: fit-content;
}

@media screen and (width <= 1440px) {
  .another-cards-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
}

@media screen and (width <= 1280px) {
  .another-cards-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
}

@media screen and (width <= 1024px) {
  .another-cards-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (width <= 650px) {
  .another-cards-wrapper {
    display: grid;
    grid-template-columns: auto auto;
  }

  .another_card {
    z-index: 1;
  }
}

@media screen and (width <= 480px) {
  .another-cards-wrapper {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 10px;
  }
  .loading_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    width: fit-content;
  }

  .card {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100px;
  }

  .another_card {
    z-index: 1;
    width: auto;
  }
  .discount {
    font-size: 13px;
    padding: 1px 15px;
  }

  .title {
    /* font-size: 11px; */
    /* height: 79px; */
  }
  .display_deals {
    /* height: 625px; */
  }
}

.wishlist_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.display_deals {
  overflow-x: hidden;
}

.initial_about_store,
.expandText {
  padding: 14px 31px 0px 14px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.9s;
}
.initial_about_store {
  padding: 0px 14px 0 14px;
  white-space: pre-wrap;
  text-align: justify;
}
#about_store span {
  display: block;
  font-weight: 600;
}
