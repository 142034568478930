@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Rubik", sans-serif !important;
}

.product_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.coupons_savings {
  width: 90%;
  margin: auto;
  padding: 10px;
}

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 9px 20px rgba(46, 35, 94, 0.07);
  width: fit-content;
  padding: 20px 40px;
}

.product_offer {
  color: brown;
  font-weight: bold;
  font-size: 30px;
}

.price_drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.coupon {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 9px 20px rgba(46, 35, 94, 0.07);
  width: fit-content;
  transition: 0.2s;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.product_img {
  width: 120px;
}

.coupon:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.offer {
  border-right: 3px dashed rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.coupon_offer {
  font-weight: bold;
  font-size: 30px;
  color: blue;
  margin-right: 5px;
}

.coupon_code {
  font-weight: bold;
  color: blue;
}

.coupon_data {
  padding-block: 10px;
  text-wrap: wrap;
  width: 619px;
}

.coupon_copy {
  padding: 10px 30px;
  margin-block: 20px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin-right: 20px;
  font-weight: 600;
  transition: all 0.2s;
}

.coupon_copy:hover {
  cursor: pointer;
}

.coupon_copy:active {
  scale: 0.97;
}

.coupons_wrapper {
  text-align: center;
}

@media screen and (width <= 600px) {
  .coupon {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    gap: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .coupon_data {
    font-size: 10px;
  }

  .coupon_offer {
    font-size: 16px;
    padding: 0px;
  }

  .coupons_savings {
    width: 100%;
  }

  .coupon_copy {
    padding: 5px 10px;
  }

  .coupon_offer {
    margin-right: 0px;
  }
}

@media screen and (width = 536px) {
  .coupon_copy {
    padding: 5px 10px;
    margin-left: 15px;
  }
}

@media screen and (width <= 400px) {
  .coupon {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 10px;
    gap: 5px;
    width: 100%;
  }

  .coupon_data {
    font-size: 8px;
  }

  .coupon_offer {
    font-size: 14px;
    padding: 0px;
  }

  .coupons_savings {
    width: 100%;
  }

  .coupon_copy {
    padding: 5px 10px;
  }

  .coupon_offer {
    margin-right: 0px;
  }
}

/* applying coupon styling  */
#scroll-text {
  display: flex;
  margin-left: 49px;
  margin-top: 60px;
  width: auto;
  justify-content: center;
}

#scroll-text span {
  font-size: 18px;
  font-weight: 1000;
  padding-right: 10px;
}

.coupon_li {
  list-style: none;
  width: auto;
  padding: 10px;
  display: flex;
  border: 1px solid gray;
}

.load {
  /* display: none; */
  width: 20px;
  height: 20px;
  margin: 0px auto 0;
  border: solid 3px #8822aa;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.applyBtn {
  background-color: #fd7e14;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: none;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Rubik", sans-serif !important;
  font-size: 16px;
  padding: 10px 20px;
  /* margin: 9px 0px 20px 0px; */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  display: none;
}

.storeImg_container {
  width: 12vw;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0px 0px 43%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
}
.storeImg_container img {
  width: 65px;
}
.applyingCouponText {
  list-style: none;
  margin-left: 20%;
  margin-top: 45px;
}
.applyingCouponText li {
  display: block;
  font-weight: 450;
  font-size: 19px;
  letter-spacing: 0.5px;
  height: 52px;
}
.couponsList {
  list-style: none;
  display: flex;
}
.couponsList li {
  border: 0.5px solid rgb(187, 184, 184);
  margin: 10px;
  padding: 5px 5px 0px 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  height: 35px;
  width: 150px;
  border-radius: 3px;
  color: rgb(66, 66, 66);
}
.couponsList li span {
  width: 75%;
  overflow: hidden;
}
.couponsList li:first-child {
  margin-left: 0px;
}

.loading-container {
  display: inline-block;
  margin-left: -57px;
  margin-right: 13px;
  margin-top: 4px;
  position: absolute;
}
.test_loading-circle {
  border: 3px solid #ffffff;
  border-top: 3px solid #6a6b6c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.loading-circle {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
