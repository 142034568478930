.slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .slider-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .popularSection {
    box-sizing: border-box;
    display: flex;
    width: 745px;
    height: 137px;
    border-radius: 5px;
    flex-grow: 1;
    overflow: hidden;
    /* padding: 9px; */
    position: relative;
    width: 100%;
    
  }
  .couponBtns,.slider-container span{
    cursor: pointer;
  }
  
 .couponData{
    display: -webkit-box;
    display: block;
    display: -ms-flexbox;
    -ms-flex: 0 0 auto;
    border-radius: 5px;
    height: 100%;
    border: 1px dashed #DDD8C2;
    padding: 10px;
    padding: 10px;
    overflow: hidden;
    border-radius: 5px;
    background-color: #fff;
    width: 918 !important;
 }
  
  
  
  .left-arrow ,.right-arrow{
    /* left: 10px; */
    border: none;
    padding: 0px 5px 0px 5px;
    font-size: 20px;
    cursor: pointer;
    background: none;
  }
  
 
  

  .sliderHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    width: 100%;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  